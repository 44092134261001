<template>
  <div class="GeneratedResult">
    <div class="back">
      <div @click="backCLick"><i class="el-icon-arrow-left"></i> 返回</div>
      <div @click="CopyText">复制全文</div>
      <div @click="htmlDownload(1)">下载Word</div>
      <div @click="htmlDownload(2)">下载PDF</div>
    </div>
    <div class="banner">
      <div class="title">
        {{ Result[0].title }}
      </div>

      <div class="content" v-for="(item, index) in Result" :key="index">
        {{ item.content }}
      </div>
      <div class="endnode" v-for="it in Result1" :key="it">
        {{ it }}
      </div>
      <div class="foot">正文总计{{ num }}字</div>
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import { htmlToWordDownload, htmlTopdfDownload } from 'api/writtenAi'
import qs from 'qs'
export default {
  name: 'GeneratedResult',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    Result: {},
  },
  data() {
    //这里存放数据
    return {
      Result1: [],
      copyText: '',
      num: 0,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 下载
    async htmlDownload(id) {
      console.log(this.Result)
      var title =
        "<h1 style='text-align:center'>" +
        this.Result[0].title +
        '</h1>' +
        '<p>' +
        '<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
        this.copyText +
        '</p>' +
        '<p >' +
        this.Result1 +
        '</p>' +
        "<p style='text-align: right'>" +
        '总计' +
        this.num +
        '字' +
        '</p>'
      console.log(title)
      let type
      if (id == 1) {
        type = 'docx'
      } else {
        type = 'pdf'
      }

      let data = {
        fileName: this.Result[0].title + '.' + type,
        content: title,
      }
      let res
      if (id == 1) {
        res = await htmlToWordDownload(qs.stringify(data))
      } else {
        res = await htmlTopdfDownload(qs.stringify(data))
      }
      console.log(res)
      const blob = new Blob([res], {
        type: 'application/octet-stream',
      })
      const fileName = data.fileName
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName)
      }
    },

    // 复制全文
    CopyText() {
      let oInput = document.createElement('textarea')
      oInput.value = this.copyText

      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '已成功复制全文',
        type: 'success',
      })
      oInput.remove()
    },
    backCLick() {
      this.$emit('backCLick')
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.Result.forEach((it, idx) => {
      this.num += it.quantity
      this.copyText += it.content
      if (it.endnode.length > 0) {
        this.Result1.push(`[${idx + 1}]` + it.endnode)
      }
    })
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
}
</script>
<style lang="scss" scoped>
.GeneratedResult {
  position: absolute;
  padding: 40px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .banner {
    height: 90vh;
    overflow-y: auto;
  }
  .back {
    display: flex;
    align-items: center;
    width: 100%;
    height: 53px;
    padding-left: 12px;
    box-sizing: border-box;
    line-height: 53px;

    div {
      margin-right: 30px;
      cursor: pointer;
      color: #ff6900;
      &:nth-child(1) {
        color: #999;
      }
    }
  }

  .title {
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    margin-bottom: 40px;
  }

  .content {
    font-size: 14px;
    line-height: 1.8;
    text-indent: 2em;
    text-justify: inter-cluster;
  }
  .endnode {
    margin-top: 10px;
    font-size: 13px;
    line-height: 20px;
    color: #666;
  }
  .foot {
    color: #333;
    text-align: right;
    margin-top: 10px;
    font-size: 15px;
  }
}
</style>
