<template>
  <div class="DraftAIGC">
    <div class="back" @click="$router.go(-1)">
      <i class="el-icon-arrow-left"></i> 返回
    </div>
    <div class="conts">
      <div class="titl">
        <div class="titl_qu"></div>
        领导主题讲话AIGC稿
      </div>
      <div class="banner">
        <div class="text_left">
          <div class="tit"><span>*</span> 输入主题:</div>
          <el-input
            type="textarea"
            class="text_cont"
            placeholder="不得超过100字"
            v-model="textarea"
            rows="3"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </div>
      </div>

      <div class="banner">
        <div class="tit"><span>*</span> 类型选择:</div>
        <div class="bos">
          <div
            v-for="(item, idx) in articleType"
            :key="idx"
            @click="neiClick(item)"
            :class="netNum == item ? 'wil' : ''"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="banner">
        <div class="tit"><span>*</span> 风格选择:</div>
        <div class="bos">
          <div
            v-for="(item, idx) in styleType"
            :key="idx"
            @click="getClick(item)"
            :class="getNum == item ? 'wil' : ''"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="banner">
        <div class="text_right">
          <div class="boxs">
            <div class="region">
              <div class="bg">区域选择项：</div>
              <div class="department" :class="departmentVal ? 'vat' : ''">
                {{ departmentVal ? departmentVal : "省、市区域选择" }}
              </div>
            </div>
            <el-cascader
              v-model="value"
              :options="addr"
              expand-trigger="hover"
              change-on-select
              :props="{ value: 'label', label: 'label' }"
              @change="handleChange"
              placeholder="省、市区域选择"
            ></el-cascader>
            <div class="tis">【非必选，选择有助于更精准】</div>
          </div>
          <div class="boxs">
            <div class="region">
              <div class="bg">部门选择项：</div>
              <div class="departmentType">
                <el-select v-model="departmentTypeValue" placeholder="部门选择">
                  <el-option
                    v-for="item in departmentType"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="tis">【非必选，选择有助于更精准】</div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="shenc" @click="generate">生成</div>
    </div>
    <GeneratedResult v-if="show" @backCLick="backCLick" :Result="Result" />
    <!-- 检验成语券弹框 -->
    <ContinuationTips
      :Tipsmessage="currentTips"
      :isShow="isShow"
      :number="number"
      ref="ContinuationTips"
    />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  RecommendCorpusEssay,
  CorpusTypeSelection,
  RegionSelection,
  deductIdiomCoupon,
} from "api/writtenAi";
import { createLoading } from "utils/utils";
import addr from "./addr";
import GeneratedResult from "./GeneratedResult";
import ContinuationTips from "components/ContinuationTips";

import { settings } from "api/service";
export default {
  name: "DraftAIGC",
  //import引入的组件需要注入到对象中才能使用
  components: {
    GeneratedResult,
    ContinuationTips,
  },
  data() {
    return {
      // 讲话
      articleType: [],
      // 风格
      styleType: [],
      // 部门
      departmentType: [],
      netNum: "内部讲话",
      getNum: "风格A",
      value: [],
      departmentVal: "",
      departmentTypeValue: "",
      value1: [],
      textarea: "",
      addr: addr,
      options: [],
      Result: [],
      show: false,
      isShow: false,
    };
  },

  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 扣除成语券
    async deductIdiomCoupons() {
      let params = {
        type: 10,
        num: Number(this.$route.query.pase),
      };
      let res = await deductIdiomCoupon(params);
      if (res.code == 200) {
        let params = {
          topic: this.textarea,
          region: this.departmentVal,
          department: this.departmentTypeValue,
          wordCount: this.$route.query.anum,
        };
        let loading = createLoading(".DraftAIGC");
        let res = await RecommendCorpusEssay(params);
        loading.close();
        if (res.code == 200) {
          this.Result = res.data;
          this.show = true;
        } else {
          this.$message.error("生成失败");
        }
      }
    },
    // 地区
    backCLick() {
      this.show = false;
    },
    async CorpusTypeSelections() {
      let data = await CorpusTypeSelection();
      if (data.code == 200) {
        this.articleType = data.data.data.articleType;
        this.styleType = data.data.data.styleType;
        this.departmentType = data.data.data.departmentType;
      }
    },
    handleChange(val) {
      this.departmentVal = val.join("/");
    },
    neiClick(id) {
      this.netNum = id;
    },
    getClick(id) {
      this.getNum = id;
    },
    async generate() {
      if (!this.textarea) {
        this.$message.error("请输入主题");
        return;
      }
      let data = await settings();
      if (data.data.aiImg == 1) {
        this.isShow = false;
        this.deductIdiomCoupons();
      } else {
        this.isShow = true;
        this.$refs.ContinuationTips.numbers(this.$route.query.pase, 999);
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.CorpusTypeSelections();
    // this.RegionSelections();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.DraftAIGC {
  position: relative;
  .back {
    width: 100%;
    height: 53px;
    padding-left: 12px;
    box-sizing: border-box;
    line-height: 53px;
    color: #999;
    cursor: pointer;
  }
  .conts {
    padding-left: 39px;
    box-sizing: border-box;

    .titl_qu {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #ff6500;
      margin-right: 15px;
    }
    .titl {
      display: flex;
      align-items: center;

      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .banner {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      .text_left {
        display: flex;
        font-size: 14px;
        .tit {
          margin-top: 20px;
          width: 90px;
        }
        span {
          color: #e1242c;
          margin-right: 5px;
        }

        .text_cont {
          margin-left: 15px;
          width: 500px;
          ::v-deep .el-textarea__inner {
            resize: none !important;
          }
        }
      }
      .text_right {
        .region {
          display: flex;
          align-items: center;
          .bg {
            width: 105px;
          }
        }
        .boxs {
          position: relative;
          font-size: 14px;
        }
        .tis {
          font-size: 12px;
          color: #999999;
          margin: 9px 0 18px 100px;
        }
        .el-cascader {
          position: absolute;
          top: 0;
          left: 100px;

          width: 500px;
          opacity: 0;
        }
        .department {
          width: 500px;
          height: 50px;
          background: #ffffff;
          border: 1px solid #c8cdda;
          border-radius: 5px;
          color: #4587ff;
          line-height: 50px;
          padding-left: 20px;
          box-sizing: border-box;
        }
        .departmentType {
          width: 500px;
          height: 50px;

          .el-select {
            width: 100%;
            height: 100%;

            ::v-deep input::-webkit-input-placeholder {
              font-size: 10px;
              color: #4587ff;
            }
          }
        }
        .vat {
          color: #333;
        }
      }
      .bos {
        display: flex;
        width: 500px;
        margin-left: 25px;

        div {
          cursor: pointer;
          width: 97px;
          height: 48px;
          background: #ffffff;
          border: 1px solid #c8cdda;
          border-radius: 5px;
          text-align: center;
          line-height: 51px;
          color: #999;
          margin-right: 45px;
          &:hover {
            background: #eaf0ff;
            border: 1px solid #4587ff;
            border-radius: 5px;
            color: #4587ff;
          }
        }
        .wil {
          background: #eaf0ff;
          border: 1px solid #4587ff;
          border-radius: 5px;
          color: #4587ff;
        }
      }
      .tit {
        margin-top: 20px;
        width: 90px;
        font-size: 14px;
        color: #000;
      }
      span {
        color: #e1242c;
        margin-right: 5px;
      }
    }
  }
  .foot {
    width: 100%;
    margin-top: 50px;
    .shenc {
      cursor: pointer;
      width: 300px;
      height: 50px;
      border-radius: 25px;
      text-align: center;
      line-height: 50px;
      color: #ff6900;
      font-size: 20px;
      margin: 0 auto;
      border: 1px solid #ff6900;
    }
  }
}
</style>
